.overview {
    display: flex;
    justify-content: space-between;
}

.content {
    border-radius: 10px;
    background-color: black;
    width: 30%;
    padding: 10px;
    align-items: stretch;
}

.read_more {
    width: 100%;
    color: white;
    background-color: #94692d;
    padding: 8px;
    border-radius: 10px;
    position: relative;
    bottom: 0;
}

.read_more:hover {
    background-color: #FF4136;
}

.read_more_inline{
    text-decoration: none;
    color: #94692d;
    cursor: pointer;
}

.read_more_inline:hover{
    color:#FF4136;
}

#link{
    color:#94692d;
    text-decoration: none;
}

#link:hover{
    color:#FF4136;
}

.article_image {
    text-align: center;
    max-width: 1000px;
}

@media screen and (max-width: 950px) {
    .overview {
        display: block;
    }

    .content {
        width: 100%;
        margin-top: 10px;
    }
}