#form_holder{
    background-color: #333;
    display: block;
    max-width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    margin: 0 auto;
    margin-top: 30px;
}

#form {
    display: block;
    padding: 10%;
    padding-top: 0;
}

form > input[type=text], form > input[type=email], form > button, form > textarea {
    width: 100%;
    border-radius: 10px;
}

textarea {
    resize: vertical;
}

button {
    background-color: #94692d;
    padding: 10px;
}

button:hover{
    background-color: #FF4136;
}
