#quotes_container{
    background: black;
    text-align: center;
    display: flex;
    border: solid 1px #94692D;
    border-radius: 10px;
}

#quote {
    border-radius: 10px;
    flex: 1;
}

#next_quote, #previous_quote {
    background-color: transparent;
    border: none;
    color: #94692D;
    border-radius: 10px;
}

#next_quote:hover, #previous_quote:hover {
    color: #FF4136;
}