#content_holder {
    background-color: black;
    border: solid 1px #94692D;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}

#content_holder:hover{
    background-color: #292929;
}

@media screen and (max-width: 800px) {
    #content_holder {
        margin-bottom: 20px;
    }
}