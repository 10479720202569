html,
body {
    height: 100vh;
    margin: 0;
}

body {
    background-color:#151515;
    font-family: "Segoe UI", Verdana, sans-serif;
    color: white;
}

#view {
    padding-top: 100px;
}

#nav {
    position: fixed;
    width: 100%;
    height: 70px;
    line-height: 50px;
    display: flex;
    z-index: 3;
    top: 0;
    left: 0;
    background-color: #292929;
    justify-content: space-around;
    align-items: center;
}

.nav-item {
    display: flex;
    align-items: center;
    text-decoration: none;
    border-radius: 20px;
    padding: 0 5px;
}

.nav-item:hover {
    background-color: black;
}

.nav-item.active {
    background-color: #94692D;
}

.nav-item.active .nav-text {
    color: black;
}

.nav-item:hover .nav-text {
    color: #94692D;
}

.nav-text {
    display: inline;
    color: #94692d;
    text-decoration: none;
    font-size: 20px;
}

.nav-icon {
    padding-top: 10px;
    align-items: center;
    margin-right: 2px;
}

.nav-icon > img {
    width: 24px;
    height: 24px;
}

#view{
    min-height: 90vh;
    padding-left: 10%;
    padding-right: 10%;
}

#footer{
    margin-top: auto;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid #94692d;
}

#footer-text {
    text-align: center;
}

h1, h2, h3, h4 {
    color: #94692d;
}

h1 > a, h2 > a, h3 > a {
    text-decoration: none;
    color: #94692d;
}

h1 > a:hover, h2 > a:hover, h3 > a:hover {
    color:#FF4136;
}

li > a{
    color:#FF4136;
}

.link{
    color:#FF4136;
}

#route {
    margin-bottom: 30px;
}

::-webkit-scrollbar {
    width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #94692d; 
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #FF4136; 
}

.link{
    text-decoration: none;
    color:#94692d;
}

.link:hover{
    color:#FF4136;
}

#sticky {
    top: 70px;
    background-color: #151515;
    position: -webkit-sticky;
    position: sticky;
    padding-bottom: 10px;
}

.text_container{
	width: 40%;
}

.flex{
	display: flex;
	justify-content: space-between;
}


#pdf {
    background-color: #151515;
}

#footer-logo-image {
    width: 100px;
    height: auto;
}

#songs-list {
    border-radius: 10px;
    border: solid 1px #94692D;
    background-color: black;
    margin-top: 10px;
    overflow-y: auto;
    width: 100%;
    height: 30vw;
}

#songs-list-and-autoplay{
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 5px;
}

input[type="checkbox"] {
    accent-color: #94692D;
}

.song-holder {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin: 10px;
    cursor: pointer;
    padding-left: 10px;
    border-radius: 10px;
}

.song-holder:hover {
    background-color: #292929;
}

.song-title, .song-artist {
    margin: 0;
}

.song-artist {
    margin-bottom: 10px;
}

.song-number {
    min-width: 30px;
}

#music {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
}

.music-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.arrows {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: white;
    font-size: 4vw;
}

.arrows:hover {
    background-color: transparent;
    color: #94692D;
}

.song-holder.active {
    background-color: #FF4136;
}

.song-holder.active h4 {
    color: #292929;
}

#current-title, #current-artist {
    color: white;
    margin: 0;
}

#current-image-holder {
    margin: 10px;
    width: 30vw;
    height: 30vw;
    position: relative;
}

#current-image {
    width: 100%;
    height: 100%;
    cursor: pointer;
    object-fit: cover;
}

input[type="range"] {
    accent-color: #94692D;
    width: 80%;
}

.control-btn {
    border: none;
    background-color: transparent;
    color: white;
    cursor: pointer;
    font-size: 18px;
}
  
.control-btn:hover {
    color: #94692D;
    background-color: transparent;
}
  
.control-btn svg {
    pointer-events: none;
}

.time-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.player-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    margin: 0 auto;
}

#spotify-logo {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    z-index: 2;
    pointer-events: none;
}

@media screen and (max-width: 870px) {
    #music {
        flex-direction: column;
        justify-content: baseline;
        gap: 10px;
        min-width: 80vw;
        max-width: 80vw;
    }

    #songs-list {
        margin-top: 0;
        height: 50vw;
    }

    #current-image-holder {
        min-width: 80vw;
        max-width: 80vw;
        min-height: 80vw;
        max-height: 80vw;
        width: 100%;
        height: 100%;
    }

    .music-controls {
        min-width: 100%;
        max-width: 100%;
        height: auto;
        align-items: center;
        justify-content: center;
    }

    .flex {
        flex-direction: column;
    }
    .text_container {
        order: 1;
        width: 100%;
    }

    .image_slider_container {
        order: 2;
        width: 100%;
    }

    #nav {
        top: 20px;
        height: 40px;
        width: 90%;
        left: 50%;          
        transform: translateX(-50%);
        border-radius: 40px;
        z-index: 3;
    }

    .nav-text {
        display: none;
    }

    .nav-icon {
        display: inline;
    }


    .nav-item:hover {
        background-color: initial;
    }

    .nav-item.active {
        background-color: #94692d;
    }

    #view {
        padding-top: 60px;
    }
}
