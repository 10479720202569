#grid{
    padding-top: 30px;
    display: grid;
    align-items: stretch;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

#filter{
    border: none;
    margin: 0;
    padding-top: 5px;
}

#filter input[type=text], #filter select {
    border-radius: 10px;
    padding-left: 5px;
    width: 250px;
}

#filter_components {
    display: flex;
    justify-content: space-between;
    padding-top:0;
}

.opts > label {
    margin-right: 10px;
}

@media screen and (max-width: 1260px) {
    #filter input[type=text] {
        width: 200px;
    }

    #filter select {
        width: 100px;
    }
}

@media screen and (max-width: 800px) {
    #sticky {
        background-color: #151515;
        top: 0;
        z-index: 1;
        width: 100%;
    }

    .scrolled {
        padding-top: 70px;
        transition: padding 0.3s ease;
    }

    .not-scrolled {
        padding-top: 0;
    }

    #filter_components {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        gap: 5px;
        left: 0;
        right: 100%;
    }

    #filter input[type=text] {
        width: 20vw;
    }
    
    #filter select {
        width: 20vw;
    }
    #grid {
        display: block;
    }
    label, #filter select, input[type="text"] {
        font-size: x-small;
    }
}