#interesting{
	display: flex;
	justify-content: space-between;
    flex-direction: row;
}

.content{
	padding:1%;
	border-radius: 10px;
	background: black;
    border: solid 0.5px #94692D;
}

#world-map {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    border: solid 1px #94692D;
    background-color: black;
    padding: 20px;
}
#map-info {
    max-width: "40vw";
    display: "flex";
    flex-direction: "column";
}

#world-map div {
    margin-bottom: 10px;
}

.composable-map {
    height: 60vh;
    width: auto;
}

@media screen and (max-width: 950px) {
    #interesting {
        flex-direction: column;
    }

    #map-info {
        width: 100%;
    }

    .composable-map {
        width: 75vw;
        height: auto;
    }

}