.table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #94692d;
}

.table  tr{
    text-align: left;
    padding: 30px;
    border: 1px solid #94692d;
}

.table td, .table th {
    padding: 10px;
}

.table thead{
    background-color:#151515;
}

.table > tbody > tr:hover{
    background-color: #292929;
}

#filter {
    border: solid 1px #94692d;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
}

#filter input {
    width: 50px;
}

#filter_components {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
}

#minors_electives {
    display: flex;
    flex-direction: row;
    gap: 20px
}

#minors_electives > div {
    width: 25vw;
    background-color: black;
    border-radius: 10px;
    border: solid 1px #94692d;
    padding: 0 10px;
}

.overview {
    margin-top: 20px;
}

.progress {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
}

.progress div {
    font-size: xx-large;
    text-decoration: bold;
}

.progress-bar-outer {
    background-color: #d9d9d9;
    height: 10px;
    width: 200px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.progress-bar-inner {
    background-color: #94692d;
    height: 100%;
    border-radius: 5px;
}

#bac, #ib {
    width: 35vw;
}

@media screen and (max-width: 950px) {
    #bac, #ib {
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    #minors_electives {
        display: block;
    }

    #minors_electives > div {
        width: 100%;
        margin-bottom: 20px;
    }
}