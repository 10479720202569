.popup_holder{
    position: absolute;
    left: 50%;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    width:100%;
    height:100%;
}

.popup_content{
    background-color: #424242;
    min-width: 700px;
    max-height: 500px;
    border-radius: 20px;
    padding: 60px 80px;
    padding-top: 0;
    overflow-y: auto;
}

.header{
    display: flex;
    align-items: center;
}

.sticky-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #424242;
    padding-top: 60px;
    padding-bottom: 15px;
}

#title {
    padding-left: 20px;
}

#back {
    height: 10%;
}

@media screen and (max-width: 800px) {
    .popup_content {
        min-width: 250px;
    }
}